<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <modal
      :store-module-name="STORE_MODULE_NAME"
      :is-modal-active.sync="isModalActive"
      :show.sync="show"
      :data="modalData"
      @finish="(value)=>callBack(value)"
    />
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <div
        class="item-container"
      >
        <b-card
          v-for="(item,index) in dataOption"
          :key="index"
          no-body
          class="mb-0 item p-2"
        >
          <b-input-group :prepend="$t('Category name')">
            <b-form-input v-model="item.name" />
            <b-input-group-append>
              <b-button
                variant="success"
                @click="updateLabel(index)"
              >
                <feather-icon icon="SaveIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <hr>
          <b-input-group :prepend="$t('Search option')">
            <b-form-input v-model="item.search" />
            <b-input-group-append>
              <b-button
                variant="primary"
                @click.stop="openModal({
                  data: {},
                  listSearchOptionId: item._id,
                })"
              >
                <feather-icon icon="PlusIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <div class="scroller mt-1">
            <div
              class="scroller-content"
            >
              <b-table-simple
                hover
                small
                caption-top
                responsive
              >
                <b-thead head-variant="dark">
                  <b-tr>
                    <b-th>
                      {{ $t('Image') }}
                    </b-th>
                    <b-th>
                      {{ $t('Name') }}
                    </b-th>
                    <b-th>
                      {{ $t('Action') }}
                    </b-th>
                  </b-tr>
                </b-thead>

                <b-tbody>
                  <template v-if="item.list.length<1">
                    <b-tr>
                      <b-td
                        colspan="3"
                        class="text-center"
                      >
                        <p>
                          {{ $t('No matching records found') }}
                        </p>
                      </b-td>
                    </b-tr>
                  </template>
                  <template>
                    <b-tr
                      v-for="(list,indexList) in filterOption(index, item.list)"
                      :key="indexList"
                    >
                      <b-td>
                        <b-avatar
                          v-if="list.image"
                          size="40"
                          :src="list.image"
                        />
                        <b-avatar
                          v-else
                          size="40"
                          :src="require('@/assets/images/blank/no_image.png')"
                        />
                      </b-td>
                      <b-td>
                        <div
                          v-for="(name, indexName) in list.name"
                          :key="indexName"
                          class=""
                        >{{ name.lang.toUpperCase() }} : {{ name.value }}</div>
                      </b-td>
                      <b-td>
                        <b-dropdown
                          variant="link"
                          no-caret
                          :right="$store.state.appConfig.isRTL"
                        >
                          <template #button-content>
                            <feather-icon
                              icon="MoreVerticalIcon"
                              size="16"
                              class="align-middle text-body"
                            />
                          </template>
                          <b-dropdown-item
                            @click.stop="openModal({
                              data: list,
                              listSearchOptionId: item._id,
                            })"
                          >
                            <feather-icon
                              icon="EditIcon"
                            />
                            <span class="align-middle ml-50">{{ $t('Edit') }}</span>
                          </b-dropdown-item>
                          <b-dropdown-item @click="deleteList(index, indexList, list._id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">{{ $t('Delete') }}</span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </b-td>
                    </b-tr>
                  </template>
                </b-tbody>
              </b-table-simple>
            </div>
          </div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            class=""
            @click="deleteLabel(index)"
          >
            <feather-icon
              icon="TrashIcon"
              size="25"
            />
          </b-button>
        </b-card>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class=""
          style="height: 40vh;"
          @click="addLabel()"
        >
          <feather-icon
            icon="PlusIcon"
            size="25"
          />
        </b-button>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BInputGroup,
  BInputGroupAppend,
  BCard,
  BImg,
  BAvatar,
  BFormInput, BButton,
  BOverlay,
  BDropdownItem,
  BDropdown,
  BTableSimple,
  BTr,
  BTd,
  BTbody,
  BThead,
  BTh,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import perPageOptions from '@/perPageOptions'
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/store/services/hero/searchOption'
import debounce from 'debounce'
import Modal from './Modal.vue'

const STORE_MODULE_NAME = 'searchOption'

export default {
  components: {
    BCard,
    BTr,
    BTd,
    BThead,
    BTh,
    BTbody,
    BTableSimple,
    BInputGroup,
    BInputGroupAppend,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    BOverlay,
    BButton,
    BDropdown,
    BDropdownItem,
    // BFormFile,
    // BBadge,
    // BLink,
    // BMedia,
    // BAvatar,
    Modal,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      avatarText,
      show: false,
      modalData: {},
      isModalActive: false,
      dataOption: [{
        _id: null,
        name: '',
        search: '',
        list: [],
      }],
      perPageOptions,
    }
  },
  computed: {

  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    this.get()
    this.typing = debounce(this.typing, 500)
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
  },
  setup() {
    return { STORE_MODULE_NAME }
  },
  methods: {
    filterOption(index, list) {
      let data = list
      if (this.dataOption[index].search !== '') {
        const regex = new RegExp(this.dataOption[index].search, 'i')
        data = data.filter(p => (regex.test(p.name.map(n => n.value))))
      }
      return data
    },
    callBack(data) {
      const item = data.data.data
      const index = this.dataOption.findIndex(e => e._id.toString() === item.listSearchOptionId.toString())
      if (index > -1) {
        const indexList = this.dataOption[index].list.findIndex(e => e._id.toString() === item._id.toString())
        if (indexList > -1) {
          Object.assign(this.dataOption[index].list[indexList], item)
        } else {
          this.dataOption[index].list.push(item)
        }
      }
    },
    openModal(item) {
      this.isModalActive = true
      this.modalData = item
    },
    deleteList(index, indexList, id) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to delete')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.show = true
            store
              .dispatch(`${STORE_MODULE_NAME}/optionDeleteList`, id)
            // eslint-disable-next-line no-unused-vars
              .then(result => {
                this.show = false
                this.dataOption[index].list.splice(indexList, 1)
                // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
              })
              .catch(error => {
                this.show = false
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
              })
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        })
    },
    deleteLabel(orderBy) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to delete')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.show = true
            store
              .dispatch(`${STORE_MODULE_NAME}/optionDeleteLabel`, orderBy)
              // eslint-disable-next-line no-unused-vars
              .then(result => {
                this.show = false
                this.dataOption.splice(orderBy, 1)
                // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
              })
              .catch(error => {
                this.show = false
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
              })
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        })
    },
    updateLabel(orderBy) {
      const obj = {
        name: this.dataOption[orderBy].name,
        orderBy,
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/optionUpdateLabel`, obj)
        .then(result => {
          this.show = false
          const { data, list } = result.data
          this.dataOption[orderBy].name = data.name
          this.dataOption[orderBy].list = list
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    addLabel() {
      const obj = {
        orderBy: this.dataOption.length,
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/optionAddLabel`, obj)
        .then(result => {
          this.show = false
          const { data, list } = result.data
          this.dataOption.push({
            _id: data._id,
            name: data.name,
            search: '',
            list,
          })
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    get() {
      const obj = {

      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/optionGet`, obj)
        .then(result => {
          this.show = false
          const { data } = result.data
          const options = []
          for (let index = 0; index < data.length; index += 1) {
            const item = data[index]
            options.push({
              _id: item._id,
              name: item.name,
              search: '',
              list: item.list,
            })
          }
          this.dataOption = options
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

    <style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    .v-select-custom {
      display: inline-block;
      width: 50;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
    .item-container {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;

      overflow-x: auto;
      white-space: nowrap;
      gap: 10px;
      padding: 8px;
      height: 75vh;
      box-sizing: border-box;
      scrollbar-color: gray white;
    }
    .item {
      min-width: 450px;
      overflow: hidden;
      height: 60vh;
    }
    .scroller {
      flex: 1; /* Take the remaining space */
      overflow-y: auto;
      scrollbar-color: gray white;
      width: 100%;
    }
    .scroller-content {
      display: flex;
      flex-direction: column;
    }
    .center {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
    </style>
