<template>
  <div>
    <b-modal
      :visible="isModalActive"
      :title="dataId ? $t('Edit category') : $t('Add category')"
      ok-title="Accept"
      @change="(val) => $emit('update:is-modal-active', val)"
    >
      <validation-observer ref="formData">
        <b-form class="p-2">
          <b-row class="justify-content-center p-2">
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="$t('Image')"
                  label-for="register-image"
                >
                  <!-- media -->
                  <b-media no-body>
                    <b-media-aside>
                      <b-link>
                        <b-img
                          v-if="image"
                          ref="previewEl"
                          rounded
                          :src="image"
                          height="80"
                        />
                        <b-img
                          v-else
                          ref="previewEl"
                          :src="require('@/assets/images/blank/no_image.png')"
                          rounded
                          height="80"
                        />
                      </b-link>
                    <!--/ avatar -->
                    </b-media-aside>

                    <b-media-body class="mt-75 ml-75">
                      <!-- upload button -->
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        size="sm"
                        class="mb-75 mr-75"
                        @click="$refs.refInputEl.$el.click()"
                      >
                        {{ $t("Upload") }}
                      </b-button>
                      <b-form-file
                        ref="refInputEl"
                        accept=".webp, .jpeg, .jpg, .png, .gif"
                        :hidden="true"
                        plain
                        @change="inputImageRenderer"
                      />
                      <!--/ upload button -->

                      <!-- reset -->
                      <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-secondary"
                        size="sm"
                        class="mb-75 mr-75"
                        @click="resetImage"
                      >
                        {{ $t("Reset") }}
                      </b-button>
                      <!--/ reset -->
                      <b-card-text>{{ $t('Allowed') }} JPG, GIF {{ $t('or') }} PNG. {{ $t('Max size of') }} 300KB</b-card-text>
                    </b-media-body>
                  </b-media>
                <!--/ media -->
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-card-text>{{ $t("Name") }}</b-card-text>
              </b-col>
              <b-col
                v-for="(item , index) in language"
                :key="index+'name'"
                cols="12"
              >
                <!-- firstName -->
                <!-- firstName -->
                <b-form-group
                  :label="item.name"
                  :label-for="item.name+'name'"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="item.name+'name'"
                    :vid="item.name+'name'"
                  >
                    <b-form-input
                      v-if="name[index]"
                      :id="item.name+'name'"
                      :name="item.name+'name'"
                      :value="name[index]['value']"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="item.name"
                      @input="inputLanguage($event,item.lang ,index, 'name')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-card-text>{{ $t("Detail") }}</b-card-text>
              </b-col>
              <b-col
                v-for="(item , index) in language"
                :key="index+'detail'"
                cols="12"
              >
                <!-- firstName -->
                <!-- firstName -->
                <b-form-group
                  :label="item.name"
                  :label-for="item.name+'detail'"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="item.name+'detail'"
                    :vid="item.name+'detail'"
                  >
                    <b-form-textarea
                      v-if="detail[index]"
                      :id="item.name+'detail'"
                      :name="item.name+'detail'"
                      :value="detail[index]['value']"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="item.name"
                      @input="inputLanguage($event,item.lang ,index, 'detail')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

            </b-row>
          </b-row>
        </b-form></validation-observer>

      <template #modal-footer>
        <div class="w-100">
          <p class="float-left mb-0" />
          <div
            class="float-right"
          >
            <div class="d-flex mt-2">
              <b-button
                variant="primary"
                class="mr-2"
                @click="validationForm()"
              >
                {{ $t("Submit") }}
              </b-button>
              <b-button
                type="button"
                variant="outline-secondary"
                @click="$emit('update:is-modal-active', false)"
              >
                {{ $t("Cancel") }}
              </b-button>
            </div>
          </div>

        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  BModal,
  VBModal,
  BRow,
  BCol,
  // BTabs,
  // BTab,
  BCardText,
  BFormCheckbox,
  BButton,
  BFormGroup,
  BMedia,
  BForm,
  BMediaAside,
  BLink,
  BImg,
  BMediaBody,
  BFormFile,
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'
// import { ThumbsUpIcon } from 'vue-feather-icons'

export default {
  components: {
    BFormGroup,
    BMedia,
    BForm,
    BMediaAside,
    BLink,
    BImg,
    BMediaBody,
    BFormFile,
    BFormInput,
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BModal,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    // BTabs,
    // BTab,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    // ValidationProvider,
    // ValidationObserver,
    // BForm,

    // BFormGroup,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isModalActive',
    event: 'update:is-modal-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isModalActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      dataId: null,
      image: '',
      name: [],
      detail: [],
      listSearchOptionId: null,
      required,
    }
  },
  computed: {
    language() {
      return store.state.appConfig.systemConfig ? store.state.appConfig.systemConfig.language : []
    },
  },
  watch: {
    isModalActive(val) {
      if (val) {
        const {
          data,
          listSearchOptionId,
        } = this.data

        if (Object.entries(data).length === 0) {
          this.initValues()
          if (this.name.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.name.findIndex(element => element.lang === this.language[index].lang)
              if (indexLanguage < 0) {
                this.name.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
          }
          if (this.detail.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.detail.findIndex(element => element.lang === this.language[index].lang)
              if (indexLanguage < 0) {
                this.detail.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
          }
        } else {
          const {
            _id,
            name,
            detail,
          } = data
          this.dataId = _id
          this.name = name
          this.detail = detail
          if (this.name.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.name.findIndex(element => element.lang === this.language[index].lang)
              if (indexLanguage < 0) {
                this.name.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
            for (let index = 0; index < this.name.length; index += 1) {
              const indexLanguage = this.language.findIndex(element => element.lang === this.name[index].lang)
              if (indexLanguage < 0) {
                this.name.splice(index, 1)
              }
            }
          }
          if (this.detail.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.detail.findIndex(element => element.lang === this.language[index].lang)
              if (indexLanguage < 0) {
                this.detail.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
            for (let index = 0; index < this.detail.length; index += 1) {
              const indexLanguage = this.language.findIndex(element => element.lang === this.detail[index].lang)
              if (indexLanguage < 0) {
                this.detail.splice(index, 1)
              }
            }
          }
        }
        this.listSearchOptionId = listSearchOptionId
      }
    },
  },
  methods: {
    inputLanguage(input, lang, index, type) {
      if (type === 'name') {
        if (this.name[index].lang === lang) {
          this.name[index].value = input
        }
      } else if (type === 'detail') {
        if (this.detail[index].lang === lang) {
          this.detail[index].value = input
        }
        console.log(this.detail)
      }
    },
    inputImageRenderer(input) {
      // eslint-disable-next-line prefer-destructuring
      if (input.target.files && input.target.files[0]) {
        if (input.target.files[0].size > 300000) {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: `${this.$t('Allowed')} JPG, GIF ${this.$t('or')} PNG. ${this.$t('Max size of')} 300KB`,
            },
          })
          return
        }

        const reader = new FileReader()
        reader.onload = e => {
          this.image = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    validationForm() {
      this.$refs.formData.validate().then(success => {
        if (success) {
          const obj = {
            id: this.dataId,
            image: this.image,
            name: this.name,
            detail: this.detail,
            listSearchOptionId: this.listSearchOptionId,
          }
          this.$emit('update:show', true)
          if (this.dataId !== null) {
            store
              .dispatch(`${this.storeModuleName}/optionUpdateList`, obj)
              .then(result => {
                this.$emit('update:is-modal-active', false)
                this.$emit('update:show', false)
                console.log('fetch Success : ', result)
                this.$emit('finish', result)
                // this.showToast('success', 'bottom-right')
              })
              .catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                this.$emit('update:show', false)
                // this.showToast('danger', 'bottom-right')
              })
          } else {
            delete obj.id
            store
              .dispatch(`${this.storeModuleName}/optionAddList`, obj)
              .then(result => {
                this.$emit('update:is-modal-active', false)
                this.$emit('update:show', false)
                console.log('fetch Success : ', result)
                this.$emit('finish', result)
                // this.showToast('success', 'bottom-right')
              })
              .catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                this.$emit('update:show', false)
                // this.showToast('danger', 'bottom-right')
              })
          }
        }
      })
    },
    resetImage() {
      this.image = ''
    },
    initValues() {
      this.dataId = null
      this.image = ''
      this.name = []
      this.detail = []
      this.listSearchOptionId = null
    },

  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  #add-new-user-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
  </style>
